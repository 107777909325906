<template>
 <div class="mt-5 bg-white rounded-2xl" style="min-height: 80vh;">
   <LicIndVlrFilters class="lic-ind-vlr-filters p-5 mb-3"/>
   <LicIndVlrForm class="lic-ind-vlr-form p-5 flex p-justify-center"/>
 </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'LicIndVlrHome',
  components: {
    LicIndVlrFilters: defineAsyncComponent(() => import('./filters.vue')),
    LicIndVlrForm: defineAsyncComponent(() => import('./form.vue'))
  }
}
</script>
<style scoped>

</style>
